import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import styled from 'styled-components'
import Layout from '../../components/Layout'
import hipaa from './hipaa.pdf'
import disclosureAndFinancial from './disclosure_statement_and_financial_agreement.pdf'
import informedConsent from './informedconsent_to_individual_therapy.pdf'
import telehealth from './telehealth_informed_consent.pdf'

const StyledDiv = styled.div`
  a{
    display:flex;
    align-items: flex-end;
    justify-content: center;
    padding:1em;
  }
`
const FormsPage = () => {
  const imgStyle = {width: "3rem"}
  return (
    <Layout>
      <p>
        Below you will find important forms. You will be asked to review and sign
        these forms at your first appointment. You may print, review and sign the
        forms prior to your first appointment to save time, or you can simply wait
        and do so in the session. Please feel free to ask questions about these
        forms at any time.
      </p>

      <StyledDiv>
        <a href={telehealth} target="_blank" rel="noreferrer">
          <StaticImage src="../../images/pdf.png" style={imgStyle}/>
          <p>Informed Conset to Telehealth Therapy</p>
          <StaticImage src="../../images/download.png" style={imgStyle}/>
        </a>
        <a href={disclosureAndFinancial} target="_blank" rel="noreferrer">
          <StaticImage src="../../images/pdf.png" style={imgStyle}/>
          <p>Disclosure Statement and Financial Agreement</p>
          <StaticImage src="../../images/download.png" style={imgStyle}/>
        </a>
        <a href={hipaa} target="_blank" rel="noreferrer">
          <StaticImage src="../../images/pdf.png" style={imgStyle}/>
          <p>HIPPA & Privacy Practices</p>
          <StaticImage src="../../images/download.png" style={imgStyle}/>
        </a>
        <a href={informedConsent} target="_blank" rel="noreferrer">
          <StaticImage src="../../images/pdf.png" style={imgStyle}/>
          <p>Informed Consent to Individual Therapy</p>
          <StaticImage src="../../images/download.png" style={imgStyle}/>
        </a>
      </StyledDiv>
    </Layout>
  )
}

export default FormsPage